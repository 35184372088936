import { defineMessages } from 'react-intl'

export default defineMessages({
  Services: 'Services',
  Solutions: 'Solutions',
  Products: 'Touristic Products',
  References: 'References',
  Partner: 'Partner',
  AboutUs: 'About Us',
  Career: 'Career',
  Contact: 'Let´s talk',
  Projects: 'Projects',
  itconsulting: 'Web Technology Consulting',
  conceptstrategy: 'Software Concept & Strategy',
  projectmanagement: 'Project Management',
  uiuxdesign: 'UX Engineering & UI Design',
  development: 'Web Application & Platform Development',
  systemintegration: 'System Integration & Architecture',
  appdevelopment: 'App Development',
  systemengineering: 'Systems Engineering',
  hcmsintegration: 'Headless CMS Integration',
  tools: 'Tools',
  mightyc: 'mightyC®',
  travelsandbox: 'TravelSandbox®',
  freshms: 'freshMS',
  fusion: 'Fusion',
  quickstart: 'Quickstart',
  technologies: 'Technologies',
  whyAgile: 'Why Agile?',
  newsroom: 'Newsroom',
  projectmanager: 'Project Manager',
  seniorprojectmanager: 'Project Manager',
  businessDeveloperSalesManager: 'Business Developer / Sales Manager',
  salesdirector: 'Sales Director',
  devops: 'DevOps Engineer',
  accountant: 'Accountant',
  receptionist: 'Receptionist',
  technicalprojectmanager: 'Technical Project Manager',
  fullstackDev: 'Fullstack Developer',
  softwareArchitect: 'Software Architect',
  softwaretester: 'Software Tester',
  seniorSoftwareArchitect: 'Senior Software Architect',
  seniorJavaDeveloper: 'Senior Java Developer',
  hrGeneralist: 'HR Generalist /  Personalreferent',
  contentManager: 'Content Manager',
  seniorUiUxDesigner: '(Senior) UI / UX Designer',
  seniorPHPFullStackTitle: 'Senior Full Stack Developer PHP',
  frontendReactTitle: 'Frontend Developer React',
  frontendDesigner: 'Frontend Designer',
  companyWebsite: 'Visit Company Website',
  allReferences: 'All References',
  CaseStudies: 'Case Studies',
  Blog: 'Blog',
  blogPath: 'https://blog.freshcells.de/en/',
  SystemModernization: 'IT System Modernization',
  UxUiAudit: 'UX/UI Audits',
})
